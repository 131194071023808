<template>
    <section class="sec-registro-pagos-detalles p-4">
        <div class="d-middle f-15 gap-2 mb-4">
            <p class="text-blue f-400 cr-pointer" @click="goBackMain">Registro de pagos</p>
            <i class="icon-right-open text-86 f-16" />
            <p class="f-400 text-blue cr-pointer" @click="goBack">{{ data.torre }}</p>
            <i class="icon-right-open text-86 f-16" />
            <p class="f-400">{{ 'Información del pago' }}</p>
        </div>
        <div class="row">
            <div class="col-12 col-lg-5">
                <div class="d-middle mb-4">
                    <el-tooltip content="Regresar" placement="bottom" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm rounded-pill">
                        <i class="icon-arrow-left-circle f-20 cr-pointer" @click="goBack" />
                    </el-tooltip>
                    <p class="f-20 f-500 ml-2 mr-auto">Información del pago</p>
                    <el-dropdown v-if="actualizarEstado" trigger="click">
                        <div :class="`${colorEstados[pago.estado]} f-14 fr-light f-400 br-4 w-fit px-2 h-31px d-middle-center cr-pointer`">
                            {{ textosEstados[pago.estado] }}
                            <i v-if="true" class="icon-down-open ml-1" />
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(info,index) in estados" :key="index">
                                <div :class="`${colorEstados[info.id]} f-14 fr-light f-400 br-4 w-fit px-2 h-24px d-middle-center cr-pointer my-2`" @click="cambiarEstado(info)">
                                    {{ info.nombre }}
                                </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <div v-else :class="`${colorEstados[pago.estado]} f-14 fr-light f-400 br-4 w-fit px-2 h-31px d-middle-center cr-pointer`">
                        {{ textosEstados[pago.estado] }}
                    </div>
                </div>
                <div style="padding-left: 36px">
                    <!-- valor -->
                    <div class="d-middle my-3">
                        <img src="/img/generales/pago_efectivo.svg" alt="" class="" />
                        <p class="f-500 f-15 ml-2">{{ convertMoney(pago.valor_mes_pago) }}</p>
                    </div>
                    <div class="d-middle my-3">
                        <p class="f-500 f-15 wf-120px">{{ 'Administración' }}</p>
                        <p class="f-300 f-15 ml-2">{{ pago.created_at | helper-fecha('MMMM YYYY') }}</p>
                    </div>
                    <div class="d-middle my-3">
                        <p class="f-500 f-15 wf-120px">{{ 'Registrado por' }}</p>
                        <div class="d-middle my-3">
                            <div class="wh-32 d-middle-center bg-general rounded-circle">
                                <i class="icon-account-outline text-white f-24" />
                            </div>
                            <p v-if="pago.registrado" class="f-300 f-15 ml-2">{{ pago.registrado.nombre }} {{ pago.registrado.apellido }}</p>
                            <p v-else class="f-300 f-15 ml-2">-----</p>
                        </div>
                    </div>
                    <div class="d-middle my-3">
                        <p class="f-500 f-15 wf-120px lh-18">{{ 'Fecha y hora de registro' }}</p>
                        <p class="f-300 f-15 ml-2">{{ pago.fecha_soporte | helper-fecha('hh:mm a, DD MMM YYYY') }}</p>
                    </div>
                    <div class="">
                        <p class="f-500 f-15">{{ 'Comentarios del residente' }}</p>
                        <p class="f-300 f-15">{{ pago.comentario_residente }}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-auto d-middle">
                <galeria-cinco ref="gallriaRef" :listado="imagenes" />
                <!--
                <div class="d-middle-center border br-4" style="width: 380px; height: 380px">
                    <el-image v-if="pago.imagen" class="h-100" :src="pago.imagen" :zoom-rate="1.2" :preview-src-list="[pago.imagen]" :initial-index="4" fit="cover" @error="img = '/img/no-imagen/noimagenanuncio.svg'" />
                    <img v-else src="/img/no-imagen/noimagenanuncio.svg" alt="" class="h-80 w-80" />
                </div> -->
            </div>
        </div>
        <!-- partials -->
        <modal ref="abrirCambiarEstado" titulo="Verificar pago" adicional="Si, cambiar" @adicional="updateEstado">
            <p class="text-center f-500 f-15 text-5d">¿Desea cambiar el estado de este pago a</p>
            <p :class="`${colorTexto[estado]} text-center f-500 f-15 text-5d`">"{{ texto }}" ?</p>
        </modal>
    </section>
</template>
<script>
import Administracion from '~/services/pago_administracion'
import moment from 'moment'

export default {
    data(){
        return {
            search: '',
            switchSinControlPagos: false,
            data: {
                torre: 'Torre 1 - Apto 2021'
            },
            estados:[
                {
                    id: 2,
                    nombre: 'Pago sin verificar'
                },
                {
                    id: 3,
                    nombre: 'En mora'
                },
                {
                    id: 4,
                    nombre: 'Paz y Salvo'
                },
            ],
            colorEstados: {
                1: 'bg-pendiente text-white',
                2: 'bg-sin-verificar text-white',
                3: 'bg-mora text-white',
                4: 'bg-paz-salvo text-white',
                5: 'bg-sin-control',
            },
            textosEstados: {
                1: 'Pago pendiente',
                2: 'Pago sin verificar',
                3: 'En mora',
                4: 'Paz y Salvo',
                5: 'Sin control de pago',
            },
            aprobadoPor: {
                1: 'Administrador',
                2: 'Contador',
            },
            pago: {
                registrado: {
                    nombre: '',
                    apellido: '',
                },
            },
            imagenes: [],
            actualizarEstado: 0,
            estado: '',
            texto: '',
            colorTexto: {
                3: 'text-danger',
                4: 'text-success',
            }
        }
    },
    mounted(){
        this.getRegistroPagoVivienda();
    },
    methods: {
        goBackMain(){
            this.$router.push({ name: 'pagos.admin.registro.pagos' })
        },
        goBack(){
            this.$router.push({ name: 'pagos.admin.registro.pagos.detail', params: {id: this.$route.params.id} })
        },
        async getRegistroPagoVivienda(){
            try {
                this.actualizarEstado = 0;

                const params = {
                    'idViviendaPago': this.$route.params.id_historial
                }
                const { data } = await Administracion.listarRegistroPagoVivienda(params);
                this.pago = data.data;
                this.imagenes = data.data.imagenes;
                this.$refs.gallriaRef.seleccionarImg(this.imagenes[0].id,this.imagenes[0].img);
                await this.calcularFecha();
            } catch (error){
                console.error(error);
            }
        },
        calcularFecha(){
            const fechaActual = moment().format('YYYY-MM');
            const fechaPago = moment(this.pago.created_at).format('YYYY-MM');
            if (fechaActual === fechaPago) this.actualizarEstado = 1;
        },
        cambiarEstado(info){
            this.estado = info.id;
            this.texto = info.nombre;
            this.$refs.abrirCambiarEstado.toggle()
        },
        async updateEstado(){
            try {
                const payload = {
                    'idViviendaPago': this.$route.params.id_historial,
                    'estado': this.estado,
                }
                const { data } = await Administracion.updateEstadoPago(payload);
                if (data.success){
                    this.$refs.abrirCambiarEstado.toggle();
                    this.notificacion('','Actualizado correctamente','success');
                    this.goBack();
                }
            } catch (error){
                return this.errorCatch(error);
            }
        }
    },
}
</script>
<style lang="scss" scoped>
$pendientes: #5A80EACC;
$sinVerificar: #FF8316;
$enMora: #FF4F4F;
$pazSalvo: #28D07B;
$sinControl: #868686;
.color{
    &-pendientes{
        color: $pendientes;
    }
    &-sin-verificar{
        color: $sinVerificar;
    }
    &-mora{
        color: $enMora;
    }
    &-paz-salvo{
        color: $pazSalvo;
    }
    &-sin-control{
        color: $sinControl;
    }
}
.bg{
    &-pendiente{
        background: $pendientes;
    }
    &-sin-verificar{
        background: $sinVerificar;
    }
    &-mora{
        background: $enMora;
    }
    &-paz-salvo{
        background: $pazSalvo;
    }
    &-sin-control{
        background: #DBDBDB;
        color: #868686;
    }
}
</style>